import React from 'react';
import Arguments from './arguments'
import Options from './options'
import { SiGraphql } from "react-icons/si";

const Controls = props => {
  return (
    <div className='flex flex-col md:flew-row md:w-2/5 my-5'>

      <h3 className='text-lg md:text-xl flex items-center mb-5 font-bold border-l-4 border-yellow-400 pl-2'>
        {props.scaleName}
      </h3>

      <Arguments arguments={props.arguments} currArgumentObject={props.currArgumentObject} 
                 setArgument={props.setArgument} currArgument={props.currArgument} />

      <Options currArgument={props.currArgument} options={props.options}
               setCurrOption={props.setCurrOption} 
               currOption={props.currOption} />
    </div>
  )
}


export default Controls