import React from 'react';
import styled from 'styled-components'
import tw from "twin.macro"
// import Prism from "prismjs"
// import prism from '../../../../styles/prism.css'
import { GatsbyImage } from "gatsby-plugin-image"

const Container = styled.div`
  code[class*="language-"],
    pre[class*="language-"] {
      ${tw`bg-gray-800 m-0`}
      font-family: 'Source Code Pro', monospace;
      white-space: pre-wrap; 
      ${tw`text-xs lg:text-sm text-gray-50`}
    }
  pre[class*="language-"] {
    /* min-height: 130px; */
    height: 140px;
    white-space: pre-wrap;
    ${tw`pt-2 px-2`}
    code {
      text-shadow: none;
      span {
        text-transform: none;
        ${tw`font-bold text-yellow-200`}
      }
    }
  }
`

const Visuals = props => {
  const Code  = props.currOption ? props.currOption.code : "";

  return (
    
    <Container className='md:mx-4 h-100 flex flex-col my-5 md:w-3/5'>
        <pre className="language-r rounded">
          <code className='language-r' dangerouslySetInnerHTML={{ __html: Code }} />
        </pre>
        {props.currOption ? <GatsbyImage loading='eager' className='rounded shadow mt-2' alt="ggplot2tor image" image={props.currOption.image.childImageSharp.gatsbyImageData} /> : ''}
    </Container>
  )
}


export default Visuals