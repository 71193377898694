import React, { useState } from 'react';
import Controls from '../components/scales/container/controls'
import Visuals from '../components/scales/container/visuals'
import Shell from '../components/global/shell'
import Container from '../components/global/container'

const ScalesContainer = props => {

  const scale = props.pageContext.data;

  // Create hook of current argument
  const [currArgument, setCurrArgument] = useState(props.pageContext.data[0].attribute);
  const [currArgumentObject, setCurrArgumentObject] = useState(props.pageContext.data[0]);
  const [currOptions, setCurrOptions] = useState(props.pageContext.data[0] ? props.pageContext.data[0].variants : null);
  const [currOption, setCurrOption] = useState(props.pageContext.data[0].variants ? props.pageContext.data[0].variants[0] : null);


  function updateCurrArgument(e) {
    // Set string and object of current arugment
    setCurrArgument(e);

    if (e) {
      const argForName = scale.filter(a => {
        return(a.attribute === e);
      });

    setCurrArgumentObject(argForName ? argForName[0] : undefined);

    const options = argForName.map(v => v.variants);
      setCurrOptions(options[0]);
    }
  }

  return (
    <Shell>

      <Container>
        <div className='mt-5'>
          
          <div className='flex flex-col md:flex-row'>
            <Controls arguments={scale} setArgument={updateCurrArgument} 
                      currArgument={currArgument} 
                      scaleName={props.pageContext.title}
                      currArgumentObject={currArgumentObject} 
                      options={currOptions}
                      currOption={currOption}
                      setCurrOption={setCurrOption} />

            <Visuals currOption={currOption} />

          </div>

        </div>
      </Container>

    </Shell>
  )
}


export default ScalesContainer