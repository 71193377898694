import React from 'react';
import styled from 'styled-components'
import tw from "twin.macro"

const YellowBox = styled.div`
  user-select: none;
`

const ArgumentsContainer = styled.div`
  a {
    color: rgba(37, 99, 235, 1);
    ${tw`hover:underline`}
  }
`;

const Arguments = props => {
  const args = props.arguments;

  return (
    <div className="shadow-lg bg-white rounded">
      <ArgumentsContainer className='md:h-64 shadow p-4 rounded'>

        <span className='text-sm text-gray-600 font-bold'>ARGUMENTS</span>
        <div className='flex flex-row flex-wrap my-4'>
          {args.map((e, i) => {
            const activeClass = e.attribute === props.currArgument ? true : false;
            const activeBackground = activeClass ?  e.attribute === 'without the scale' ? 'bg-red-200' : 'bg-yellow-100' : '';

            return <YellowBox className={`${e.attribute === 'without the scale' ? 'bg-red-50 hover:bg-red-200 border border-gray-500' : 'bg-gray-100 border border-gray-500 hover:bg-yellow-100'} px-2 py-1 mr-1 mb-1 cursor-pointer text-xs md:text-sm rounded  ${activeBackground}`} key={i} 
                        onClick={e => props.setArgument(e.target.innerHTML)}>{e.attribute}</YellowBox>
          })} 
        </div>
        <p className='text-sm' dangerouslySetInnerHTML={{ __html: props.currArgumentObject ? props.currArgumentObject.information : ""}} />
        
      </ArgumentsContainer>
    </div>
  )
}


export default Arguments