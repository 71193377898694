  
import React, { useEffect } from 'react';
import styled from 'styled-components'
import tw from "twin.macro"


const VariantItem = styled.div`
  user-select: none;
`

const OptionsContainer = styled.div`
  a {
    color: rgba(37, 99, 235, 1);
    ${tw`hover:underline`}
  }
`;


const Options = props => {
  const options = props.options;

  // When user clicks on option, it updates it
  const updateCurrentVariant = (option) => {
    const myOption = options.filter(o => {
      return o.name === option;
    });

    props.setCurrOption(myOption[0]);
  }
  
  // If the argument changes, reset the current
  // variant to undefined
  useEffect(() => {
    props.setCurrOption(props.options ? props.options[0] : null);
  }, [props.currArgument]);

  return (
    <div className="shadow-lg rounded bg-white mt-2">
      <OptionsContainer className='md:h-52 rounded shadow p-4 mt-5'>

        {options ? (options.length === 1 ? 
          null : <>
            <span className='text-sm text-gray-600 font-bold'>Options</span>
            
            <div className='flex flex-row flex-wrap my-4'>
              {options.map((o, i) => {
                const isCurrentOption = props.currOption ? props.currOption.name : '';

                const activeClass = o.name === isCurrentOption ? true : false;

                return <VariantItem className={`bg-gray-100 border border-gray-500 px-2 py-1 mr-1 mb-1 cursor-pointer text-xs md:text-sm rounded hover:bg-yellow-50 ${activeClass ? 'bg-yellow-100' : ''}`}
                  key={i} onClick={e => updateCurrentVariant(e.target.innerHTML)}>
                  {o.name}
                  </VariantItem>
              })}
            </div>
            <p className='text-sm pt-3' dangerouslySetInnerHTML={{ __html: props.currOption ? props.currOption.information : "" }} />
          </>) : null}
      </OptionsContainer>
    </div>
  )
}


export default Options